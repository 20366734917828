<div id="menu-bar">
    <div id="back-button-container" *ngIf="containerSessionViewActive">
        <div id="visp-back-button" (click)="backButtonClicked()">
            <div id="visp-back-button-arrow">&#8678;</div>
            <p>Back to dashboard</p>
        </div>
    </div>
    
    <div id="system-logo-container">
        <img src="{{ logoImagePath }}">
    </div>

    <div id="umu-logo-container">
        <img src="/assets/umu-logo-left-neg-EN.png">
    </div>

    <app-user></app-user>
</div>
