<div id="dashboard">
    <div id="main-content">
        <!--
        <div id="final-authentication" *ngIf="userIsSignedIn">
            <img src="assets/loading-indicator-4-dark.svg">
            <p>Performing final authentication</p>
        </div>
        -->

        <div id="signed-out-content-container" *ngIf="!userIsSignedIn && userSignedInCheckPerformed && signInTimeoutExpired">
            <div *ngIf="applicationName == 'Visible Speech'" id="about-container">
                <h2>About</h2>
                Visible Speech is a collection of research tools for speech analysis.
                If you do not have an account, you may visit the <a href="https://visp-demo.humlab.umu.se">demo-site</a> for a tour of its capabilties. If you wish to setup a project on the live system, 
                please get in touch with us at <a href="mailto:support@humlab.umu.se">support@humlab.umu.se</a>.<br /><br />Visible Speech is being developed by Umeå university.
            </div>
            <div *ngIf="applicationName == 'DataLab'" id="about-container">
                <h2>About</h2>
                Datalab allows you as a researcher to store your data in a version controlled manner and work with that data using online computational tools.
                <br /><br />Datalab is being developed by Humlab at Umeå university.
            </div>
            <app-signin-ctrl></app-signin-ctrl>
        </div>
        
        <app-project-manager *ngIf="userIsAuthorized" [dashboard]="this"></app-project-manager>
        
        <!--
        <div *ngIf="!systemIsReady" id="systemInitMsgContainer">
            <div id="systemInitMsg">
                <img src="assets/loading-indicator-4-light.svg">
                <div id="systemInitText">System intializing</div>
            </div>
        </div>
        -->
        
        <div id="userFailedAccessListCheckContainer" *ngIf="userIsSignedIn && !userIsAuthorized">
            <h2>Missing access grant</h2>
            <p>You have not yet been authorized to use this service. If you would like to be granted access, please get in touch with us at <a href="mailto:support@humlab.umu.se">support@humlab.umu.se</a>.</p>
        </div>

    </div>
    
    <div id="sidebar">
        <app-infobox-manager></app-infobox-manager>
    </div>

</div>