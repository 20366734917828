<div [formGroup]="form">

  <div *ngIf="userIsProjectAdmin()">
    <div class="form-control-pair-container">
      <div (click)="toggleMemberSearch()" class="form-control-pair-label">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
          &nbsp;
          Add member
      </div>
      
      <div id="search-user-input" class="form-control-pair-input">
          <label>Search user:&nbsp;</label>
          <div>
              <input type="text" id="search-user-control" (input)="onInput($event)" />
              <div id="user-select-options">
                  <ul class="select-search-results-list" *ngFor="let searchUser of searchUsers">
                      <li (click)="addMember(searchUser)" class="search-option-user-container">
                          {{searchUser.fullName}} ({{searchUser.eppn}})
                      </li>
                  </ul>
              </div>
          </div>
      </div>
    </div>
  </div>

  <br />
  <table>
    <tbody formArrayName="members">
        <tr *ngFor="let member of members.controls; let i = index" [formGroupName]="i">
            <td *ngIf="userIsProjectAdmin()">
                <i class="fa fa-minus-circle button" (click)="removeMember(member)" aria-hidden="true"></i>
            </td>
            <td *ngIf="userIsProjectAdmin()">
                <select [formControl]="member.get('role')" (change)="updateRole(member)">
                    <option value="admin">Admin</option>
                    <option value="member">Member</option>
                </select>
            </td>
            <td>
                {{ member.get('fullName').value }} ({{ member.get('eppn').value }})
            </td>
        </tr>
    </tbody>
  </table>
    
  <!--
  <br />
  <button id="member-form-save-button" [ngClass]="{'disabled': !submitBtnEnabled}" [disabled]="!submitBtnEnabled" (click)="saveForm()">
      <img *ngIf="showLoadingIndicator" src="../../../../assets/loading-indicator-4-light.svg">
      <i *ngIf="!showLoadingIndicator" class="fa fa-save" aria-hidden="true"></i>
      &nbsp;
      {{ submitBtnLabel }}
  </button>
  -->
</div>